<template>
  <div class="biochemical">
    <!-- <div class="insert-title">血压检查</div> -->
    <el-form class="qjc-clearfix">
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">血压检查</div>
        <el-form-item label="收缩压(SBP)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.sbp" @input="mustNumber(0)" placeholder="90~180"
            @blur="inputRange('sbp', 90, 180)">
            <div class="suffix qjc-fts-14" slot="suffix">
              mmHg
            </div>
          </el-input>
        </el-form-item>
        <el-form-item label="舒张压(DBP)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.dbp" @input="mustNumber(1)" placeholder="<90" @blur="inputRange('dbp', 1, 90)">
            <div class="suffix qjc-fts-14" slot="suffix">
              mmHg
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">血常规检查</div>
        <el-form-item label="白细胞(WBC)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[0].ve" @input="mustNumber(0)" placeholder="0~150"
            @blur="inputRange(0, 0, 150)">
            <div class="suffix qjc-fts-14" slot="suffix">
              10³/μl
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="血红蛋白(HGB)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[1].ve" @input="mustNumber(1)" placeholder="1~25"
            @blur="inputRange(1, 1, 25)">
            <div class="suffix qjc-fts-14" slot="suffix">
              g/dL
            </div>
          </el-input>
        </el-form-item>
        <el-form-item label="血小板(PLT)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[2].ve" @input="mustNumber(2)" placeholder="1~1800"
            @blur="inputRange(2, 1, 1800)">
            <div class="suffix qjc-fts-14" slot="suffix">
              10³/μl
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">血糖检查</div>
        <el-form-item label="空腹血糖(FG)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[3].ve" @input="mustNumber(3)"
            :placeholder="formData.examine[3].ut == 'A' ? '30～700' : '1.5～40'" @blur="inputRange(3, 30, 700, 1.5, 40)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[3].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/mol</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="糖化血红蛋白(HBA1C)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[4].ve" @input="mustNumber(4)" placeholder="1~25"
            @blur="inputRange(4, 1, 25, 0, 47.5)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[4].ut">
                <el-radio label="A">%</el-radio>
                <el-radio label="B">mmol/mol</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">血脂检查</div>
        <el-form-item label="总胆固醇(CHOL)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[5].ve" @input="mustNumber(5)"
            :placeholder="formData.examine[5].ut == 'A' ? '50～1100' : '1.2～28.5'"
            @blur="inputRange(5, 50, 1100, 1.2, 28.5)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[5].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="低密度脂蛋白(LDLC)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[6].ve" @input="mustNumber(6)"
            :placeholder="formData.examine[6].ut == 'A' ? '1～650' : '0～17'" @blur="inputRange(6, 1, 650, 0, 17)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[6].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="高密度脂蛋白(HDLC)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[7].ve" @input="mustNumber(7)"
            :placeholder="formData.examine[7].ut == 'A' ? '1～250' : '0～7'" @blur="inputRange(7, 1, 250, 0, 7)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[7].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="甘油三酯(TG)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[8].ve" @input="mustNumber(8)"
            :placeholder="formData.examine[8].ut == 'A' ? '1～9000' : '0～110'" @blur="inputRange(8, 1, 9000, 0, 110)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[8].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">mmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">肝功能检查</div>
        <el-form-item label="谷草转氨酶GOT" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[9].ve" @input="mustNumber(9)" placeholder="1~2000"
            @blur="inputRange(9, 1, 2000)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="谷丙转氨酶GPT" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[10].ve" @input="mustNumber(10)" placeholder="1~4000"
            @blur="inputRange(10, 1, 4000)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="碱性磷酸酶(ALP)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[11].ve" @input="mustNumber(11)" placeholder="1~6500"
            @blur="inputRange(11, 1, 6500)">
            <div class="suffix qjc-fts-14" slot="suffix">
              IU/L(U/L)
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="血清白蛋白(ALB)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[12].ve" @input="mustNumber(12)"
            :placeholder="formData.examine[12].ut == 'A' ? '1～7' : '10～70'" @blur="inputRange(12, 1, 7, 10, 70)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[12].ut">
                <el-radio label="A">g/dL</el-radio>
                <el-radio label="B">g/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="form-cell">
        <div class="insert-title qjc-wid-100">肾功能检查</div>
        <el-form-item label="肌酐(CRE)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[13].ve" @input="mustNumber(13)"
            :placeholder="formData.examine[13].ut == 'A' ? '0～40' : '0～3600'" @blur="inputRange(13, 0, 40, 0, 3600)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[13].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">μmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="尿酸(UA)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[14].ve" @input="mustNumber(14)"
            :placeholder="formData.examine[14].ut == 'A' ? '0～20' : '0～1200'" @blur="inputRange(14, 0, 20, 0, 1200)">
            <div class="suffix qjc-fts-14" slot="suffix">
              <el-radio-group v-model="formData.examine[14].ut">
                <el-radio label="A">mg/dL</el-radio>
                <el-radio label="B">μmol/L</el-radio>
              </el-radio-group>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item label="肾小球滤过率(EGFR)" class="qjc-fl qjc-wid-50">
          <el-input v-model="formData.examine[15].ve" @input="mustNumber(15)" placeholder="1~300"
            @blur="inputRange(15, 1, 300)">
            <div class="suffix qjc-fts-14" slot="suffix">
              ml/min/1.73m<sup>2</sup>
            </div>
          </el-input>
        </el-form-item>
      </div>
      <div class="footer-btn">
        <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">上一步</el-button>
        <el-button @click="handleSubmit" :loading="submiting" class="next qjc-fts-16 qjc-block">确认提交</el-button>
      </div>

      <el-dialog title="套餐修改" :visible.sync="updateShow" width="30%" :before-close="handleUpdateClose">
        <span>每份套餐的修改次数最多三次，请您确认修改内容</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancel-btn" @click="handleUpdateClose">取 消</el-button>
          <el-button class="submit-btn" type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import { packageOpen } from '@/utils/axios'
import { Base64 } from 'js-base64';

export default {
  name: 'biochemical',
  data () {
    return {
      submiting: false,
      updateShow: false, //修改确认弹窗
    }
  },
  props: ['type', 'formData', 'clearData'],
  inject: ['handleRefresh'],
  created () {

  },
  methods: {
    handleUpdateClose () {
      this.updateShow = false;
    },
    handleSubmit () {
      if (this.type == 'update') {
        this.updateShow = true;
      } else {
        this.submit()
      }
    },
    // 确认提交
    submit () {
      // 添加操作人
      this.formData.operator = localStorage.getItem('mobile');
      if (!this.verifyNull()) {
        //是否存在未填写信息
        // 可为空data(二选一，后面再加验证)
        var white = ['yyuserid', 'mobile'];
        const formData = JSON.parse(JSON.stringify(this.formData));

        for (var key in formData) {
          if (white.indexOf(key) == -1) {
            if (!key) {
              this.$myAlert({
                type: 'fail',
                content: '存在未填写的信息'
              });
              return false;
            }

          }
        }
        //手机号与企业id至少一项需填写
        if (!formData.yyuserid && !formData.mobile) {
          this.$myAlert({
            type: 'fail',
            content: '手机号与企业用户ID至少填写一项'
          });
          return false;
        }
        if (this.type == 'update') {
          formData.act = 'update'
        }

        if (formData.examdate == '') {
          formData.examdate = Date.parse(new Date()) / 1000
        }

        this.submiting = true;
        const type = formData.goods_type
        delete formData.goods_type

        // console.log(formData)
        // return;
        formData.birth = formData.birth.split('/').join('')
        packageOpen(formData).then(res => {

          this.submiting = false;

          if (res.code == 200) {
            this.updateShow = false;
            if (res.data) {
              const { id } = JSON.parse(Base64.decode(res.data))
              this.download(type, id)
            }
            this.clearData();
            this.$emit('checkTab', 'Personal');
            this.handleRefresh()

          } else {
            this.updateShow = false;
            this.$myAlert({
              type: 'fail',
              content: res.msg
            });
          }
        });
      }

    },
    download (type, id) {
      console.log("查看报告", type, id);
      if (type == 'AIMB') {// 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });
        window.open(openUrl.href);
      }
    },
    //验证不为空
    verifyNull () {
      let examineList = [
        { "ne": "WBC", "ve": "", "ut": "A", "name": "白细胞(WBC)" },
        { "ne": "HGB", "ve": "", "ut": "A", "name": "血红蛋白(HGB)" },
        { "ne": "PLT", "ve": "", "ut": "A", "name": "血小板(PLT)" },
        { "ne": "FG", "ve": "", "ut": "B", "name": "空腹血糖(FG)" },
        { "ne": "HBA1C", "ve": "", "ut": "A", "name": "糖化血红蛋白(HBA1C)" },
        { "ne": "CHOL", "ve": "", "ut": "B", "name": "总胆固醇(CHOL)" },
        { "ne": "LDLC", "ve": "", "ut": "B", "name": "低密度脂蛋白(LDLC)" },
        { "ne": "HDLC", "ve": "", "ut": "B", "name": "高密度脂蛋白(HDLC)" },
        { "ne": "TG", "ve": "", "ut": "B", "name": "甘油三酯(TG)" },
        { "ne": "GOT", "ve": "", "ut": "A", "name": "谷草转氨酶GOT" },
        { "ne": "GPT", "ve": "", "ut": "A", "name": "谷丙转氨酶GPT" },
        { "ne": "ALP", "ve": "", "ut": "A", "name": "碱性磷酸酶(ALP)" },
        { "ne": "ALB", "ve": "", "ut": "B", "name": "血清白蛋白(ALB)" },
        { "ne": "CRE", "ve": "", "ut": "B", "name": "肌酐(CRE)" },
        { "ne": "UA", "ve": "", "ut": "B", "name": "尿酸(UA)" },
        { "ne": "EGFR", "ve": "", "ut": "A", "name": "肾小球滤过率(EGFR)" }
      ];
      for (var i = 0; i < this.formData.examine.length; i++) {
        if (this.formData.examine[i].ve == '' && i != 15) {// i=15(肾小球过滤率)暂时可为空
          this.$myAlert({
            title: '不能为空',
            content: '请输入' + examineList[i].name
          });
          return true;
        }
      }

      return false;
    },

    //必须输入数字 参数对应examine下标
    mustNumber (index) {
      this.formData.examine[index].ve = this.formData.examine[index].ve.match(/\d/g) ? this.formData.examine[index].ve.match(/[\d || .]/g).join('') : '';
    },

    //输入偏差提示 参数1.data中examine下标  2.最小值(存在单位切换时为A单位最小值)  3.最大值(存在单位切换时为A单位最大值)  4.B最小值(存在单位切换时为B单位最小值) 5.B最大值(存在单位切换时为B单位最小值)
    inputRange (index, min, max, min2, max2) {
      if (index == 'sbp') {
        if (this.formData.sbp != '' && (this.formData.sbp < min || this.formData.sbp > max)) {
          this.$myAlert({
            title: '确认输入信息',
            content: "您填写的结果可能存在较大偏差，请您仔细核对该数值和所用单位是否正确并与您的报告一致。<br/>若您已经核对确认，并坚持以该结果进行提交和评估，我们可能会根据大数据算法对该结果进行一定程度上的修正和处理，以提供更合理的大数据评估结果和建议。"
          });
          return
        }
        return
      }
      if (index == 'dbp') {
        if (this.formData.dbp != '' && (this.formData.dbp < min || this.formData.dbp > max)) {
          this.$myAlert({
            title: '确认输入信息',
            content: "您填写的结果可能存在较大偏差，请您仔细核对该数值和所用单位是否正确并与您的报告一致。<br/>若您已经核对确认，并坚持以该结果进行提交和评估，我们可能会根据大数据算法对该结果进行一定程度上的修正和处理，以提供更合理的大数据评估结果和建议。"
          });
          return
        }
        return
      }
      if (this.formData.examine[index].ve != '') {
        if (min2 || max2 && this.formData.examine[index].ut == 'B') {//存在单位切换且为单位为B值时
          if (this.formData.examine[index].ve < min2 || this.formData.examine[index].ve > max2) {
            this.$myAlert({
              title: '确认输入信息',
              content: "您填写的结果可能存在较大偏差，请您仔细核对该数值和所用单位是否正确并与您的报告一致。<br/>若您已经核对确认，并坚持以该结果进行提交和评估，我们可能会根据大数据算法对该结果进行一定程度上的修正和处理，以提供更合理的大数据评估结果和建议。"
            });
          }
        } else {
          if (this.formData.examine[index].ve < min || this.formData.examine[index].ve > max) {
            this.$myAlert({
              title: '确认输入信息',
              content: "您填写的结果可能存在较大偏差，请您仔细核对该数值和所用单位是否正确并与您的报告一致。<br/>若您已经核对确认，并坚持以该结果进行提交和评估，我们可能会根据大数据算法对该结果进行一定程度上的修正和处理，以提供更合理的大数据评估结果和建议。"
            });
          }
        }
      }

    },

    handlePrev () {

    },
  }
}
</script>

<style scoped>
.biochemical>>>.el-form-item__label {
  width: 190px;
  font-size: 14px;
  line-height: 14px;
  /* margin-bottom: 10px; */
  text-align: right;
  color: #777F8F;
}

.biochemical>>>.el-input {
  height: 32px;
  line-height: 32px;
}

.biochemical>>>.el-input input {
  height: 32px;
  line-height: 14px;
  font-size: 14px;
  padding: 10px 12px;
}

.biochemical>>>.el-radio__label {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.biochemical {
  padding-top: 32px;

  .form-cell {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 23px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form {
    margin-top: 22px;

    .el-form-item {
      display: flex;
      align-items: center;

      margin-bottom: 24px;

      .el-input {
        width: 376px;

        .el-radio {
          font-weight: normal;
          color: #777F8F;
        }

        .suffix {
          color: #777F8F;
          margin-right: 16px;

          sub {
            display: inline-block;
            vertical-align: baseline;
            font-size: inherit;
            transform: translateY(9%)scale(.65);
          }

          sup {
            display: inline-block;
            vertical-align: top;
            font-size: inherit;
            transform: translateY(-9%)scale(.65);
          }
        }
      }

      .submit {
        width: 200px;
        height: 48px;
        margin-top: 40px;
      }
    }
  }

}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.next {
  width: 96px;
  height: 34px;
  border-radius: 17px;
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}

.prev {
  width: 96px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #6883FB;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6883FB;
  background-color: #fff;
  margin-right: 24px;
}


.cancel-btn {
  width: 80px;
  height: 40px;
  color: #606266;
  background-color: #fff;
}

.submit-btn {
  width: 80px;
  height: 40px;
  background-color: #6681FA;
  border: none;
}
</style>
